// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headlineMobile[data-v-0e0b781a] {
  flex-wrap: unset !important;
  max-width: 100%;
}
.videoButtonTT[data-v-0e0b781a] {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.spanModalCall[data-v-0e0b781a] {
  font-size: 18px;
  word-break: break-word;
  max-width: 470px;
  line-height: 2;
  text-wrap: wrap;
}
.spanModalCallMobile[data-v-0e0b781a] {
  font-size: 18px;
  word-break: break-word;
  max-width: 290px;
  line-height: 2;
  text-wrap: wrap;
}
.w-100[data-v-0e0b781a] {
  width: 100%;
}
.font15[data-v-0e0b781a] {
  font-size: 15px;
}

/*.styleHeader{
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}*/
.imageLogo[data-v-0e0b781a] {
  display: inline-block;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/modalComponents/headerModal.vue","webpack://./headerModal.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;EACA,eAAA;ACAF;ADEA;EACE,gBAAA;EACA,2BAAA;EACA,qBAAA;ACCF;ADCA;EACE,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;ACEF;ADAA;EACE,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;ACGF;ADDA;EACE,WAAA;ACIF;ADFA;EACE,eAAA;ACKF;;ADHA;;;;EAAA;AAKA;EACE,qBAAA;EACA,sBAAA;ACMF","sourcesContent":["\n.headlineMobile {\n  flex-wrap: unset !important;\n  max-width: 100%;\n}\n.videoButtonTT {\n  max-width: 250px;\n  background: rgb(97, 97, 97);\n  opacity: 1 !important;\n}\n.spanModalCall {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 470px;\n  line-height: 2;\n  text-wrap: wrap;\n}\n.spanModalCallMobile{\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 290px;\n  line-height: 2;\n  text-wrap: wrap;\n}\n.w-100 {\n  width: 100%;\n}\n.font15 {\n  font-size: 15px;\n}\n/*.styleHeader{\n  border-bottom: 1px solid var(--v-primary-base) !important;\n  margin-bottom: 15px;\n  color: var(--v-primary-base) !important;\n}*/\n.imageLogo {\n  display: inline-block;\n  vertical-align: middle;\n}\n",".headlineMobile {\n  flex-wrap: unset !important;\n  max-width: 100%;\n}\n\n.videoButtonTT {\n  max-width: 250px;\n  background: rgb(97, 97, 97);\n  opacity: 1 !important;\n}\n\n.spanModalCall {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 470px;\n  line-height: 2;\n  text-wrap: wrap;\n}\n\n.spanModalCallMobile {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 290px;\n  line-height: 2;\n  text-wrap: wrap;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.font15 {\n  font-size: 15px;\n}\n\n/*.styleHeader{\n  border-bottom: 1px solid var(--v-primary-base) !important;\n  margin-bottom: 15px;\n  color: var(--v-primary-base) !important;\n}*/\n.imageLogo {\n  display: inline-block;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
