// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorPointer[data-v-04d3fdcf] {
  cursor: pointer;
}
.spanMoreActions[data-v-04d3fdcf] {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  text-align: center;
}
.spanMoreActions .iconMoreActions[data-v-04d3fdcf] {
  font-size: 22px;
  color: #ffffff;
}
.spanMoreActions .iconMoreActionsBlack[data-v-04d3fdcf] {
  font-size: 22px;
  color: black;
}
.btnAbsolute[data-v-04d3fdcf] {
  position: absolute;
  right: 0;
  top: 0;
}
.buttonCallFrontCard[data-v-04d3fdcf] {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/userMoreActions.vue","webpack://./userMoreActions.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADEA;EACE,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,yBAAA;EACA,kBAAA;ACCF;ADAE;EACE,eAAA;EACA,cAAA;ACEJ;ADAE;EACE,eAAA;EACA,YAAA;ACEJ;ADCA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;ACEF;ADAA;EACE,iBAAA;EACA,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,yBAAA;EACA,6BAAA;EACA,0BAAA;ACGF","sourcesContent":["\n.cursorPointer {\n  cursor: pointer;\n}\n.spanMoreActions {\n  width: 18px !important;\n  height: 18px !important;\n  border-radius: 50% !important;\n  font-size: 0px !important;\n  text-align: center;\n  .iconMoreActions {\n    font-size: 22px;\n    color: #ffffff;\n  }\n  .iconMoreActionsBlack {\n    font-size: 22px;\n    color: black;\n  }\n}\n.btnAbsolute {\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n.buttonCallFrontCard {\n  background: white;\n  width: 20px !important;\n  height: 20px !important;\n  border-radius: 50% !important;\n  font-size: 0px !important;\n  margin-bottom: 5px !important;\n  margin-top: 5px !important;\n}\n",".cursorPointer {\n  cursor: pointer;\n}\n\n.spanMoreActions {\n  width: 18px !important;\n  height: 18px !important;\n  border-radius: 50% !important;\n  font-size: 0px !important;\n  text-align: center;\n}\n.spanMoreActions .iconMoreActions {\n  font-size: 22px;\n  color: #ffffff;\n}\n.spanMoreActions .iconMoreActionsBlack {\n  font-size: 22px;\n  color: black;\n}\n\n.btnAbsolute {\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n\n.buttonCallFrontCard {\n  background: white;\n  width: 20px !important;\n  height: 20px !important;\n  border-radius: 50% !important;\n  font-size: 0px !important;\n  margin-bottom: 5px !important;\n  margin-top: 5px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
